
































import { Vue, Component } from 'vue-property-decorator';
import Layout from '@/layouts/LayoutDefault.vue';

import User from '@models/user/User';
import { Action } from 'vuex-class';
import Footer from '@/components/Footer.vue';

@Component({
  components: {
    Footer,
    Layout,
  },
})
export default class MyUsersListView extends Vue {
  @Action('getUsersCurrentFirm')
  getUsersCurrentFirm!: () => Promise<void>;

  loading: boolean = false;

  get users(): User[] {
    return User.query().all();
  }

  async created() {
    this.loading = true;
    await this.getUsersCurrentFirm();
    this.loading = false;
  }
}
